import styled, { css } from 'styled-components';

// Global
import {
  FONT_FAMILIES,
  COLORS,
  DIMENSIONS,
  FONT_WEIGHTS
} from 'shared/constants/theme';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import Picture from 'shared/components/presentational/Picture';
import BaseContainer from 'shared/components/presentational/Container/Container';
// Shared
import { removeInnerContentVerticalMargin } from '../../shared/styles/mixins';
import {
  Wrapper as SharedWrapper,
  Button as SharedButton
} from '../../shared/styles/components';
// Local
import {
  BANNER_HEIGHT,
  BANNER_PHABLET_HEIGHT,
  BANNER_PHONE_HEIGHT,
  BOTTOM_BANNER_PADDING,
  TOP_BANNER_PADDING,
  TWO_IMAGES_BANNER_HEIGHT,
  TWO_IMAGES_BANNER_HEIGHT_PHONE
} from './constants';
import { BannerTemplate } from '../types';
import {
  CustomSubtitle,
  CustomTitle
} from 'shared/components/presentational/Title/sharedTitles';
const { LONG_VERTICAL_MARGIN, MEDIUM_VERTICAL_MARGIN } = DIMENSIONS;

export const BannerPrice = styled.div<{ color?: string }>`
  font-family: ${FONT_FAMILIES.brand};
  display: flex;
  margin-top: 10px;
  text-transform: uppercase;
  color: ${props => props.color ?? COLORS.black};
  align-items: center;
  p:nth-child(1) {
    font-size: ${pxInRem(56)};
    margin: 0px;
    font-weight: ${FONT_WEIGHTS.bold};
  }
`;

export const PriceDetails = styled.div`
  display: flex;
  flex-direction: column;

  p:nth-child(1) {
    font-size: ${pxInRem(24)};
    font-weight: ${FONT_WEIGHTS.bold};
    margin: 0px;
  }
`;

export const BannerDescription = styled.p<{
  color?: string;
  template?: string;
}>`
  font-family: ${FONT_FAMILIES.base};
  font-size: ${pxInRem(16)};
  margin: ${props =>
    props.template === BannerTemplate.TwoImages ? '8px 0 0' : '10px 0 0'};
  color: ${props => props.color ?? COLORS.black};
`;

/**
 * Banner title
 */
export const BannerTitle = styled(CustomTitle)`
  text-align: inherit;
  font-family: ${FONT_FAMILIES.base};
`;

/**
 * Banner subtitle
 */
export const BannerSubtitle = styled(CustomSubtitle)`
  font-size: ${pxInRem(16)};
  text-align: inherit;
`;

export const TwoImagesBannerSubtitle = styled.h3`
  margin-top: 16px;
  margin-bottom: 0;
  font-size: ${pxInRem(20)} !important;
  color: ${props => props.color ?? 'inherit'};
  text-align: inherit;
`;

/**
 * Banner heading
 */
export const BannerHeading = styled.div`
  position: relative;
  ${mediaQueries.fromTablet<{ template: string }>`
max-width: ${props =>
    props.template === BannerTemplate.TwoImages ? '360px' : '600px'};
  `};
  margin-bottom: ${props =>
    props.template === BannerTemplate.TwoImages ? '8px' : '0'};
  ${mediaQueries.toPhone<{ template: string }>`
margin-top: ${props => props.template === BannerTemplate.TwoImages && '25px'};
  `};
`;

/**
 * Banner image
 */
export const BannerImage = styled(Picture).attrs({ disableLazy: true })`
  position: absolute;
  min-width: 100%;
  max-width: unset;
  height: 100%;
  left: 50%;
  object-fit: cover;
  top: 50%;
  transform: translate(-50%, -50%);

  ${mediaQueries.toPhablet<{ template?: string }>`
      height: ${props => props.template === BannerTemplate.TwoImages && 260}px ;
      top: ${props => props.template === BannerTemplate.TwoImages && '27%'} ;
      object-fit: ${props =>
        props.template === BannerTemplate.TwoImages && 'contain'} ;
    `}
`;

export const SecondaryBannerImage = styled(Picture).attrs({
  disableLazy: true
})`
  margin-top: -220px;
  ${mediaQueries.toPhablet`
  margin-top: -100px !important;
  width: 100%;
  height: 100%;
  object-fit: contain;
`};
`;

/**
 * Banner (wrapper)
 */

export const SecondaryBanner = styled.div`
  position: relative;
  text-align: center;
  padding: 0;
`;
interface BannerProps {
  to: string;
  template: BannerTemplate;
  hasButton: boolean;
}

export const Banner = styled(BaseContainer)<BannerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${props =>
    props.template === BannerTemplate.TwoImages
      ? TWO_IMAGES_BANNER_HEIGHT
      : BANNER_HEIGHT}px;
  margin-bottom: ${LONG_VERTICAL_MARGIN}px;
  overflow: hidden;
  color: ${COLORS.white};
  padding-top: ${props =>
    props.template === BannerTemplate.TwoImages ? 0 : TOP_BANNER_PADDING}px;
  padding-bottom: ${BOTTOM_BANNER_PADDING}px;

  ${mediaQueries.toPhablet<BannerProps>`
 padding-top:${props =>
   props.template === BannerTemplate.TwoImages ? 0 : TOP_BANNER_PADDING}px;
background-color: ${props =>
    props.template === BannerTemplate.TwoImages ? COLORS.black : 'transparent'};

    padding-bottom: ${BOTTOM_BANNER_PADDING}px;
    margin: ${MEDIUM_VERTICAL_MARGIN}px 0;
    height:  ${props =>
      props.template === BannerTemplate.TwoImages
        ? TWO_IMAGES_BANNER_HEIGHT_PHONE
        : BANNER_PHABLET_HEIGHT}px  ;
  `};

  ${mediaQueries.toPhone<BannerProps>`
    height: ${props =>
      props.template === BannerTemplate.TwoImages
        ? TWO_IMAGES_BANNER_HEIGHT_PHONE
        : BANNER_PHONE_HEIGHT}px  ;
  `};

  /* Banner items layout */
  ${removeInnerContentVerticalMargin(BannerHeading)}

  ${SharedButton} {
    flex-shrink: 0;
    margin: 0;
    margin-top: ${MEDIUM_VERTICAL_MARGIN}px;
  }

  /* Template variations */
  ${props =>
    props.template === BannerTemplate.Center &&
    css<BannerProps>`
      text-align: center;
      align-items: center;
      justify-content: center;
      ${BannerSubtitle} {
        margin: 8px auto 0;
      }

      /* When banner contain a button, position it at the bottom while keeping heading centered */
      ${props =>
        props.hasButton &&
        mediaQueries.fromTablet`
      &::before {
        content: '';
      }
      justify-content: space-between;
    `}
    `}

  ${props =>
    props.template === BannerTemplate.Top &&
    `
    ${BannerSubtitle} {
      margin: 8px auto 0;
    }
    text-align: center;
    align-items: center;
    justify-content: flex-start;
  `}

  ${props =>
    props.template === BannerTemplate.Left &&
    css`
      text-align: center;
      align-items: center;
      justify-content: center;

      ${BannerSubtitle} {
        margin: 8px auto 0;
      }

      ${mediaQueries.fromTablet`
      ${BannerSubtitle} {
        margin-left: 0;
      }
      text-align: left;
      align-items: flex-start;
    `}
    `}

  ${props =>
    props.template === BannerTemplate.Right &&
    css`
      text-align: center;
      align-items: center;
      justify-content: center;
      ${BannerSubtitle} {
        margin: 8px auto 0;
      }
      ${mediaQueries.fromTablet`
      ${BannerSubtitle} {
        margin-right: 0;
      }
      text-align: right;
      align-items: flex-end;
    `}
    `}

    ${props =>
    props.template === BannerTemplate.TwoImages &&
    css`
      text-align: left;
      align-items: flex-start;
      justify-content: center;
      ${mediaQueries.toPhablet`
      & > picture::after {
        position: absolute;
        content: '';
        height: 260px;
        width: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 50%), linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 50%);
      };
      `};
      ${mediaQueries.fromTablet`
      ${BannerSubtitle} {
        margin-left: 0;
      }
      text-align: left;
      align-items: flex-start;
    `}
    `};
  /* Hide banner button on phone for alignTop and alignCenter templates */
  ${props =>
    [BannerTemplate.Top].includes(props.template) &&
    mediaQueries.toPhablet`
    ${SharedButton} {
      display: none;
    }
  `}
`;

/**
 * Heading
 */
export const HeadingContainer = styled(BaseContainer)`
  margin-top: 55px;
`;

/**
 * Wrapper
 */
export const Wrapper = styled(SharedWrapper)`
  margin-top: 0 !important;
  ${Banner}:first-child {
    margin-top: 0;
  }
`;
