import styled from 'styled-components';

import Raw from 'shared/components/presentational/Raw/Raw';
import BaseLabel from 'shared/components/presentational/Form/Label';
import BaseCheckbox from 'shared/components/presentational/Form/Checkbox';
import BaseButton from 'shared/components/presentational/Button/Button';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import { container } from 'shared/constants/mixins';
import BasePicture, {
  BaseWrapper as BasePictureWrapper
} from 'shared/components/presentational/Picture';
import { Title as SharedTitle } from '../../shared/styles/components';

export const Wrapper = styled.section`
  ${container}
`;

export const ErrorWrapper = styled.section`
  ${container}
`;

export const Title = SharedTitle;

export const OfferTypeTitle = styled.h3`
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.bold};
`;

export const CartridgeWrapper = styled.div`
  margin: 40px 0 30px;
`;

export const Cartridge = styled.div<{ color: string }>`
  margin: 10px 0;
  border: 2px solid ${props => props.color};
  border-radius: 4px;
  padding: 30px 130px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${mediaQueries.toTablet`
    padding: 30px 70px;
  `};

  ${mediaQueries.toPhablet`
    margin: 24px 0;
    padding: 32px 10px;
    flex-direction: column;
  `};
`;

export const OfferTitle = styled.p<{ color: string }>`
  font-size: ${pxInRem(24)};
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};
  color: ${props => props.color};
  margin: 0;

  ${mediaQueries.toPhablet`
      margin-bottom: 20px;
  `};
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MinorOffers = styled.div`
  margin: 40px 0 40px;
`;

const MinorOfferLogoCustomWrapper = styled(BasePictureWrapper)`
  width: fit-content;
`;

export const MinorOfferLogo = styled(BasePicture).attrs({
  height: 53,
  customWrapper: MinorOfferLogoCustomWrapper
})`
  max-height: 53px;

  ${mediaQueries.toPhablet`
    margin-bottom: 1em;
  `};
`;

export const MinorOfferPrice = styled(Raw)`
  font-size: ${pxInRem(18)};
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.bold};
  line-height: 1.5;
  flex-shrink: 0;
  & > p {
    display: inline;
  }
`;

export const MinorOfferBillingInformation = styled(Raw)`
  margin: 1em 0 3em;
`;

export const SummaryText = styled(Raw)`
  margin-bottom: 32px;
  font-size: ${pxInRem(16)};
`;

export const SubscriptionText = styled(Raw)`
  margin-bottom: 35px;
`;

export const AgreementWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 35px;
`;

export const AgreementSubWrapper = styled.div`
  flex: 1;
  margin-left: 19px;
`;

export const AgreementText = styled(Raw)`
  flex: 1;
  margin: 0 0 0 19px;
  font-size: ${pxInRem(16)};

  & > p {
    margin-bottom: 0;
  }
`;

export const AgreementError = styled.p<{ color: string }>`
  display: block;
  width: 100%;
  font-size: ${pxInRem(14)};
  color: ${props => props.color};
  margin-top: 14px;
`;

export const Checkbox = styled(BaseCheckbox)`
  flex-shrink: 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 48px;
`;

export const Button = styled(BaseButton)`
  margin-bottom: 20px;
`;

export const BankInfoLabel = styled(BaseLabel)`
  font-size: ${pxInRem(16)};
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.demi};

  ${mediaQueries.fromTablet`
    font-size: ${pxInRem(22)};
  `}
`;
