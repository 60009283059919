import { css } from 'styled-components';

import { COLORS } from 'shared/constants/theme';

export const style = css`
  [data-theme='one-shop'] {
    --channel-group-wrapper-display: grid;
    --channel-group-wrapper-gap: var(--spacing-xl);

    --channel-group-wrapper-mobile-margin: 0;
    --channel-group-wrapper-desktop-margin-top: 0;
    --channel-group-wrapper-desktop-margin: 0;
    --channel-group-wrapper-max-width: 100%;

    --channel-group-wrapper-padding-mobile: var(--container-minimum-padding);
    --channel-group-wrapper-padding-tablette: var(
      --container-minimum-padding-tablet
    );
    --channel-group-wrapper-background: var(--surface-background);

    --channel-group-grid-display: grid;
    --channel-group-grid-mobile-grid-template: repeat(4, 1fr);
    --channel-group-grid-desktop-grid-template: repeat(11, 1fr);

    --channel-group-grid-justify: flex-start;
    --channel-group-grid-gap: var(--spacing-l);
    --channel-group-grid-item-border: none;
    --channel-group-grid-item-border-radius: 4px;
    --channel-group-grid-item-flex-mobile: 0 0 calc(25% - 15px);
    --channel-group-grid-item-flex-tablette: 0 0 calc(25% - 15px);
    --channel-group-grid-item-flex-desktop: 0 0 calc((100 / 11) - 20px);
    --channel-group-grid-item-card-height-mobile: 74px;
    --channel-group-grid-item-card-height-tablette: 74px;
    --channel-group-grid-item-card-padding: var(--spacing-xs);
    --channel-group-grid-item-card-background: var(--surface-default);
    --channel-group-grid-item-image-height-mobile: unset;
    --channel-group-grid-item-image-height-tablette: unset;
  }
  [data-theme='default'] {
    --channel-group-wrapper-display: block;
    --channel-group-wrapper-gap: 0;
    --channel-group-wrapper-mobile-margin: 40px 20px 0 20px;
    --channel-group-wrapper-desktop-margin-top: 48px;
    --channel-group-wrapper-desktop-margin: auto;
    --channel-group-wrapper-max-width: 1000px;
    --channel-group-wrapper-padding-mobile: 0;
    --channel-group-wrapper-padding-tablette: 0;
    --channel-group-wrapper-background: none;
    --channel-group-grid-display: flex;
    --channel-group-grid-mobile-grid-template: none;
    --channel-group-grid-desktop-grid-template: none;
    --channel-group-grid-justify: center;
    --channel-group-grid-gap: 8px;
    --channel-group-grid-item-border: 1px solid ${COLORS.paymentGrey};
    --channel-group-grid-item-border-radius: 3px;
    --channel-group-grid-item-flex-mobile: 0 0
      calc(25% - var(--channel-group-grid-gap, 4px));
    --channel-group-grid-item-flex-tablette: 0 0
      calc(16.66% - var(--channel-group-grid-gap, 4px));
    --channel-group-grid-item-flex-desktop: 0 0
      calc(12.5% - var(--channel-group-grid-gap, 4px));
    --channel-group-grid-item-card-height-mobile: unset;
    --channel-group-grid-item-card-height-tablette: unset;
    --channel-group-grid-item-card-padding: 0;
    --channel-group-grid-item-card-background: none;
    --channel-group-grid-item-image-height-mobile: 56px;
    --channel-group-grid-item-image-height-tablette: 80px;
  }
`;
