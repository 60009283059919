/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React from 'react';
import styled from 'styled-components';

import { FormattedProps, BannerTemplate } from '../types';
import BaseButton from 'shared/components/presentational/Button';
import ApashButton from 'shared/components/presentational/Button/ApashButton';
import BaseLink from 'shared/components/presentational/CustomLink/CustomLink';
import { hasFormattedProps } from '../propsFormatter';
import { Button } from '../../shared/styles/components';
import {
  Wrapper,
  HeadingContainer,
  Banner,
  BannerImage,
  BannerHeading,
  BannerTitle,
  BannerSubtitle,
  SecondaryBannerImage,
  SecondaryBanner,
  BannerDescription,
  BannerPrice,
  PriceDetails,
  TwoImagesBannerSubtitle
} from '../styles/components';
import {
  BANNER_HEIGHT,
  BANNER_PHABLET_HEIGHT,
  BANNER_PHONE_HEIGHT
} from '../styles/constants';
import {
  BREAKPOINTS,
  BANNER_THUMBOR_FILTER
} from 'shared/components/presentational/Picture/constants';
import Title from 'shared/components/presentational/Title';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Spacer from 'shared/components/presentational/Spacer';

type Props = FormattedProps;

const CustomApashButton = styled(ApashButton)`
  margin-top: 8px;
  max-width: 353px;
  ${mediaQueries.toPhablet`
align-self: center;
text-align: center;
margin-bottom: 34px;
`}
`;

const MobileSpacer = styled(Spacer)`
  ${mediaQueries.fromPhablet`
height: 0rem
`}
`;

const STYLES: Record<BannerTemplate, { textAlign: string }> = {
  [BannerTemplate.Left]: {
    textAlign: 'left'
  },
  [BannerTemplate.TwoImages]: {
    textAlign: 'left'
  },
  [BannerTemplate.Right]: {
    textAlign: 'right'
  },
  [BannerTemplate.Center]: {
    textAlign: 'center'
  },
  [BannerTemplate.Top]: {
    textAlign: 'center'
  }
};

const HeroBanner = (props: Props) => {
  const {
    title,
    titleColor,
    titleHtmlTag,
    subtitle,
    bannerTitle,
    bannerSubtitle,
    bannerTitleColor,
    bannerTitleHtmlTag,
    bannerBackgroundImageUrl,
    bannerBackgroundImageAlt,
    bannerTemplate,
    bannerButtonLabel,
    bannerButtonTheme,
    bannerButtonUrl,
    bannerContentColor,
    bannerDescription,
    primaryMediaDesktop,
    primaryMediaMobile,
    primaryMediaTablet,
    primaryMediaAlt,
    secondaryMediaMobile,
    secondaryMediaDesktop,
    secondaryMediaTablet,
    secondaryMediaAlt,
    priceInteger,
    priceExponent,
    priceTerms,
    logotype,
    handleBannerClick
  } = props;
  const { textAlign } = STYLES[bannerTemplate];

  if (!hasFormattedProps(props)) {
    return null;
  }
  const displayPrimaryBanner =
    primaryMediaDesktop ||
    primaryMediaMobile ||
    primaryMediaTablet ||
    bannerBackgroundImageUrl;

  const displaySecondaryBanner =
    secondaryMediaDesktop || secondaryMediaMobile || secondaryMediaTablet;

  const isTwoImagesTemplate = bannerTemplate === BannerTemplate.TwoImages;

  const primaryBannerSizes = isTwoImagesTemplate
    ? {
        desktop: {
          url: primaryMediaDesktop,
          h: BANNER_HEIGHT,
          w: BREAKPOINTS.DESKTOP + 1
        },
        tablet: {
          url: primaryMediaTablet ?? primaryMediaDesktop,
          h: BANNER_PHABLET_HEIGHT,
          w: BREAKPOINTS.DESKTOP
        },
        phone: {
          url: primaryMediaMobile ?? primaryMediaDesktop,
          h: BANNER_PHONE_HEIGHT,
          w: BREAKPOINTS.TABLET
        },
        phoneMini: {
          url: primaryMediaMobile ?? primaryMediaDesktop,
          h: BANNER_PHONE_HEIGHT,
          w: BREAKPOINTS.PHONE
        }
      }
    : undefined;

  const secondaryBannerSizes = {
    desktop: {
      url: secondaryMediaDesktop,
      h: BANNER_HEIGHT,
      w: BREAKPOINTS.DESKTOP + 1
    },
    tablet: {
      url: secondaryMediaTablet ?? secondaryMediaDesktop,
      h: BANNER_PHABLET_HEIGHT,
      w: BREAKPOINTS.DESKTOP
    },
    phone: {
      url: secondaryMediaMobile ?? secondaryMediaDesktop,
      h: BANNER_PHONE_HEIGHT,
      w: BREAKPOINTS.TABLET
    },
    phoneMini: {
      url: secondaryMediaMobile ?? secondaryMediaDesktop,
      h: BANNER_PHONE_HEIGHT,
      w: BREAKPOINTS.PHONE
    }
  };

  const BannerButton = isTwoImagesTemplate ? CustomApashButton : Button;

  const CustomButton = styled(BannerButton)<{ hideForMobile: boolean }>`
    ${mediaQueries.toPhone`
      width: 100% ;
      margin-top: 0px !important;
  `}
    display: ${props => (props.hideForMobile ? 'none' : 'flex')};
    ${mediaQueries.fromDesktop`
      display: flex;
  `}
  `;
  const hideCTAOnMobile = bannerTemplate === BannerTemplate.Top;

  return (
    <Wrapper>
      {(title || subtitle) && (
        <HeadingContainer>
          <Title
            titleLabel={title}
            subtitle={subtitle}
            titleColor={titleColor}
            titleHtmlTag={titleHtmlTag}
          />
        </HeadingContainer>
      )}

      {displayPrimaryBanner ? (
        <Banner
          template={bannerTemplate}
          hasButton={Boolean(bannerButtonLabel)}
          as={bannerButtonUrl ? BaseLink : undefined}
          to={bannerButtonUrl ?? ''}
          onClick={handleBannerClick}
        >
          <BannerImage
            thumborFilter={BANNER_THUMBOR_FILTER}
            template={bannerTemplate}
            responsivesUrls={primaryBannerSizes}
            url={
              primaryMediaMobile ||
              primaryMediaDesktop ||
              bannerBackgroundImageUrl
            }
            alt={primaryMediaAlt ?? bannerBackgroundImageAlt}
          />
          {(bannerTitle || bannerSubtitle) && (
            <BannerHeading
              style={
                {
                  '--title-position': STYLES[bannerTemplate]
                    ? textAlign
                    : undefined
                } as React.CSSProperties
              }
              template={bannerTemplate}
            >
              {logotype ? <div>{logotype}</div> : null}
              {bannerTitle && (
                <BannerTitle
                  color={bannerTitleColor ?? bannerContentColor}
                  htmlTag={bannerTitleHtmlTag}
                >
                  {bannerTitle}
                </BannerTitle>
              )}

              {bannerSubtitle &&
                (isTwoImagesTemplate ? (
                  <TwoImagesBannerSubtitle
                    color={bannerTitleColor ?? bannerContentColor}
                  >
                    {bannerSubtitle}
                  </TwoImagesBannerSubtitle>
                ) : (
                  <BannerSubtitle
                    color={bannerTitleColor ?? bannerContentColor}
                  >
                    {bannerSubtitle}
                  </BannerSubtitle>
                ))}

              {bannerDescription && (
                <BannerDescription
                  template={bannerTemplate}
                  color={bannerTitleColor ?? bannerContentColor}
                >
                  {bannerDescription}
                </BannerDescription>
              )}
              {priceInteger && (
                <BannerPrice color={bannerTitleColor ?? bannerContentColor}>
                  <p>{priceInteger}</p>
                  <PriceDetails>
                    <p> {priceExponent}</p>
                    <p>{priceTerms}</p>
                  </PriceDetails>
                </BannerPrice>
              )}
            </BannerHeading>
          )}

          {bannerButtonLabel && (
            <>
              {isTwoImagesTemplate ? null : <MobileSpacer height={3} />}
              <CustomButton
                hideForMobile={hideCTAOnMobile}
                theme={bannerButtonTheme}
                to={bannerButtonUrl}
                onClick={handleBannerClick}
              >
                <BaseButton.children.Text>
                  {bannerButtonLabel}
                </BaseButton.children.Text>
              </CustomButton>
            </>
          )}
        </Banner>
      ) : null}

      {displaySecondaryBanner ? (
        <SecondaryBanner>
          <SecondaryBannerImage
            thumborFilter={BANNER_THUMBOR_FILTER}
            url={secondaryMediaMobile || secondaryMediaDesktop}
            responsivesUrls={secondaryBannerSizes}
            alt={secondaryMediaAlt}
          />
        </SecondaryBanner>
      ) : null}
    </Wrapper>
  );
};

export default HeroBanner;
