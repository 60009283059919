import { PageRequestHeaders } from 'shared/modules/page/types';
import clientCookies from 'client/cookies';
import { COOKIE_KEYS } from 'shared/modules/cookies/constants';
import getHostname from 'shared/modules/hostname/selectors/getHostname';
import { HttpClient } from 'shared/modules/page/api/HttpClient';
import getMiniSiteDomain from 'shared/helpers/zones/getMiniSiteDomain';
import getPageURI from 'shared/helpers/uri/getPageURI';

/**
 * The purpose of this action is to get the uniqueId.
 * Headers logic taken from : shared/modules/page/api/fetchPage.js
 */
export default (path: string) => async (
  dispatch: any,
  getState: any,
  { uniqueId }: any
) => {
  const lang = clientCookies.get(COOKIE_KEYS.LANG);
  const hostname = getHostname(getState());
  const miniSiteDomain = getMiniSiteDomain(hostname);

  const url = getPageURI(path);

  const headers: Record<PageRequestHeaders | string, string> = {
    'X-UNIQUE-ID': uniqueId,
    'X-MINISITE-DOMAIN': miniSiteDomain
  };

  if (lang) {
    headers['X-LANG'] = lang;
  }

  return HttpClient.get(url, {
    headers,
    validateStatus: status => (status >= 200 && status < 300) || status === 400
  }).then(({ data }) => data);
};
