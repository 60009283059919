import { Media, Link, ThemeFactory, CustomTitle } from 'shared/types';

export enum BannerTemplate {
  Top = 'alignTop',
  Center = 'alignCenter',
  Left = 'alignLeft',
  Right = 'alignRight',
  TwoImages = 'twoImages'
}

interface ApashMedia extends Omit<Media, 'link'> {
  link?: Link;
}

export interface Content {
  title?: CustomTitle;
  subtitle: string | null;
  buttons: {
    bannerButton?: {
      label: string;
      labelColor: string;
      backgroundColor: string;
      link: {
        rel: string;
        href: string;
      } | null;
    };
  };
  banner: {
    logotype?: string;
    title?: CustomTitle;
    subtitle?: string;
    media?: Media;
    template: BannerTemplate;
    color?: string;
    description?: string;
    price?: {
      marketingPriceInteger: number;
      marketingPriceExponent: string;
      marketingPriceTerms: string;
    };
    productExternalId?: string;
  };
  medias?: {
    primaryBanner: {
      desktopMedia: ApashMedia;
      tabletMedia: ApashMedia;
      mobileMedia: ApashMedia;
    };
    secondaryBanner: {
      desktopMedia: ApashMedia;
      tabletMedia: ApashMedia;
      mobileMedia: ApashMedia;
    };
  };
}

export interface FormattedProps {
  title?: string;
  titleColor?: string;
  titleHtmlTag?: string;
  subtitle?: string;
  bannerTitle?: string;
  bannerTitleColor?: string;
  bannerTitleHtmlTag?: string;
  bannerSubtitle?: string;
  bannerDescription?: string;
  bannerContentColor?: string;
  bannerBackgroundImageUrl?: string;
  bannerBackgroundImageAlt?: string;
  bannerTemplate: BannerTemplate;
  bannerButtonLabel?: string;
  logotype?: string;
  primaryMediaDesktop?: string;
  primaryMediaTablet?: string;
  primaryMediaMobile?: string;
  primaryMediaAlt?: string;
  secondaryMediaDesktop?: string;
  secondaryMediaTablet?: string;
  secondaryMediaMobile?: string;
  secondaryMediaAlt?: string;
  bannerButtonTheme: ThemeFactory;
  bannerButtonUrl: string;
  priceInteger?: number;
  priceExponent?: string;
  priceTerms?: string;
  handleBannerClick?: () => void;
}
