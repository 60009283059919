import styled from 'styled-components';

import pxInRem from 'shared/helpers/styled-components/remHelper';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import BaseRaw from 'shared/components/presentational/Raw/Raw';
import Picture from 'shared/components/presentational/Picture';

export const Title = styled.h2`
  margin: ${pxInRem(20)} 0;
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.normal};
  font-size: ${pxInRem(36)};
  text-align: center;
  color: ${COLORS.black};
  ${mediaQueries.toPhablet`
    margin-bottom: ${pxInRem(16)};
    font-size: ${pxInRem(24)};
  `}
`;

export const InformationMessageWrapper = styled.div<{
  backgroundColor: string;
}>`
  display: flex;
  border-radius: 4px;
  padding: 24px;
  background-color: ${props => props.backgroundColor};
  margin-top: 50px;
  font-size: ${pxInRem(16)};
  line-height: 1.3;
`;

export const InformationMessageImageWrapper = styled.div`
  width: auto;
`;

export const InformationMessageImage = styled(Picture).attrs({
  customWrapper: InformationMessageImageWrapper
})`
  margin-right: 24px;
  max-width: 48px;
`;

export const InformationMessage = styled(BaseRaw)`
  font-size: ${pxInRem(16)};
  line-height: ${pxInRem(25)};

  & > p {
    margin-bottom: 0;
  }
`;

export const FieldsGrid = styled.div`
  display: grid;
  gap: 15px;
  margin-bottom: 32px;

  ${mediaQueries.fromTablet`
    column-gap: 40px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `}
`;

export const FieldWrapper = styled.div<{
  fullWidth?: boolean;
  zIndex?: number;
}>`
  position: relative;
  flex: 1;
  width: 100%;

  ${props =>
    props.fullWidth &&
    mediaQueries.fromTablet`
    grid-column: span 2 / span 2;
  `}

  ${props => props.zIndex && `z-index: ${props.zIndex};`}
`;
