import React from 'react';
import styled, { css } from 'styled-components';

import { container } from 'shared/constants/mixins';
import { COLORS, FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import { mediaQueries } from 'shared/helpers/styled-components/mediaQueries';
import buttonThemeFactory from 'shared/components/presentational/Button/helpers/themeFactory';
import BaseButton from 'shared/components/presentational/Button';
import BaseModal, {
  ModalScrollWrapper
} from 'shared/components/presentational/Modal/BaseModal';
import Radio from 'shared/components/presentational/Form/Radio';
import Icon from 'shared/components/presentational/Icons';
import BaseError from 'shared/components/presentational/Error/Error';
import BaseLegalNotice from 'shared/components/presentational/LegalNotice/LegalNotice';
import Checkbox from 'shared/components/presentational/Form/Checkbox';
import {
  InformationMessageWrapper,
  InformationMessage
} from 'shared/blocks/eshop/shared/styles/components';
import { FormButton } from 'shared/components/presentational/Form';

export const Wrapper = styled.section`
  ${container}
  background-color: ${COLORS.concrete};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  min-height: 80vh;
  padding-top: 24px;

  ${mediaQueries.fromTablet`
    margin-bottom: 40px;
  `}
`;

export const HeadingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  ${mediaQueries.fromTablet`
    margin-bottom: 42px;
  `}
`;

export const ErrorCGAMessage = styled.p`
  margin: 8px 0;
  padding-left: 42px;
  align-self: flex-start;
  color: ${COLORS.torchRed};
  line-height: ${pxInRem(18.75)};
`;

export const Title = styled.h1`
  font-size: ${pxInRem(30)};
  font-weight: ${FONT_WEIGHTS.bold};
  margin-top: 0;
  margin-bottom: 7px;
  text-align: center;
  ${mediaQueries.fromTablet`
    margin-top: 26px;
  `}
`;

export const Subtitle = styled.p`
  display: flex;
  align-items: center;
  font-size: ${pxInRem(14)};
  margin-bottom: 0;

  > span {
    margin-left: 13px;
  }
`;

export const Error = styled(BaseError)`
  width: 100%;
`;

export const TunnelCoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 24px;
  box-shadow: 2px 10px 30px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  background-color: ${COLORS.white};
  border-radius: 4px;
  box-shadow: 2px 10px 30px rgba(0, 0, 0, 0.04);

  ${mediaQueries.fromTablet`
    padding-bottom: 40px;
  `}
`;

const horizontalPaddingMixin = css`
  padding-left: 20px;
  padding-right: 20px;

  ${mediaQueries.fromTablet`
    padding-left: 85px;
    padding-right: 85px
  `}
`;

export const ChoiceWrapper = styled.div<{
  withHPadding?: boolean;
  withBorderB?: boolean;
  withRadiusT?: boolean;
  withRadiusB?: boolean;
}>`
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background-color: ${COLORS.white};
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;

  ${props => props.withHPadding && horizontalPaddingMixin}
  ${props => props.withBorderB && `border-bottom: 1px solid ${COLORS.gray};`}
  border-radius: ${props => (props.withRadiusT ? `4px 4px` : '0 0')} ${props =>
    props.withRadiusB ? `4px 4px` : '0 0'};
`;

export const Choice = styled.div<{ justifyContent?: string }>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: ${props => props.justifyContent || 'flex-end'};
  text-align: left;

  ${mediaQueries.fromTablet`
    flex-direction: row-reverse;
    align-items: end;
  `}
`;

export const ClickableChoice = styled.button`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  text-align: left;
  font-family: inherit;
  cursor: pointer;
  text-align: left;

  ${mediaQueries.fromTablet`
    flex-direction: row-reverse;
    align-items: baseline;
  `}
`;

export const ChoiceLabelValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 42px;
  max-width: 90%;

  ${mediaQueries.fromTablet`
    max-width: 100%;
  `}
`;

export const ChoiceValue = styled.b<{
  fontSize?: 'large' | 'medium';
}>`
  font-size: ${props =>
    props.fontSize === 'large' ? pxInRem(24) : pxInRem(18)};
  font-weight: ${FONT_WEIGHTS.bold};
  line-height: 1.3;
  & > small {
    font-size: ${pxInRem(12)};
  }
`;

export const ChoiceLabel = styled.p<{
  fontSize?: 'small' | 'medium' | 'large';
  bold?: boolean;
  withoutMarginB?: boolean;
  textAlign?: string;
}>`
  font-family: ${FONT_FAMILIES.new};
  font-size: ${props =>
    props.fontSize === 'large'
      ? pxInRem(24)
      : props.fontSize === 'medium'
      ? pxInRem(18)
      : pxInRem(16)};
  font-weight: ${props =>
    props.bold ? FONT_WEIGHTS.bold : FONT_WEIGHTS.normal};
  text-align: ${props => props.textAlign};
  margin-bottom: ${props => (props.withoutMarginB ? '0px' : ' 4px')};
`;

export const ChoiceAdditionalInfosWrapper = styled.div`
  width: 100%;

  & ~ ${ChoiceLabelValueWrapper} {
    margin-bottom: 4px;
    width: 100%;
  }

  ${mediaQueries.fromTablet`
    margin-left: 30px;
    width: 55%;

    & ~ ${ChoiceLabelValueWrapper} {
      margin-bottom: 0;
      width: 45%;
    }
  `}
`;

export const ChoiceAdditionalInfosLabel = styled(ChoiceLabel)`
  margin-top: 16px;
`;

export const ChoiceAdditionalInfosValue = styled(ChoiceValue)``;

export const ChoiceChevron = styled(Icon).attrs({
  name: 'Chevron',
  width: '30px',
  height: 'auto'
})`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

{
  /* TODO: Check Gifts and OfferForm blocks */
}
export const ChoiceRadio = styled(Radio).attrs({
  label: '',
  isRequired: false,
  color: COLORS.amaranth,
  borderColor: COLORS.gray
})`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  min-height: 25px;

  & input {
    margin-right: 0;
  }
`;

export const ChoiceCheckbox = styled(Checkbox).attrs({
  color: COLORS.amaranth,
  borderColor: COLORS.gray
})`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const RedButton = styled(FormButton).attrs({
  theme: buttonThemeFactory({
    backgroundColor: COLORS.amaranth,
    color: COLORS.white
  })
})`
  font-weight: ${FONT_WEIGHTS.bold};
`;

export const BlackButton = styled(FormButton).attrs({
  theme: buttonThemeFactory({
    backgroundColor: COLORS.black,
    color: COLORS.white
  })
})`
  font-weight: ${FONT_WEIGHTS.bold};
`;

export const UnstyledButton = styled(FormButton)`
  font-weight: ${FONT_WEIGHTS.bold};
  font-family: ${FONT_FAMILIES.new};
  width: 295px;
  text-transform: uppercase;
  ${pxInRem(16)};
  ${mediaQueries.fromTablet`
  width: 405px;
`}
`;

export const ButtonsWrapper = styled.div<{
  fixedBToPhablet?: boolean;
  order?: number;
  children?: React.ReactNode;
  withRadiusBFromPhablet?: boolean;
}>`
  ${horizontalPaddingMixin}

  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  order: ${props => props.order};
  background-color: ${COLORS.white};

  ${props =>
    Array.isArray(props.children) && props.children.filter(Boolean).length > 1
      ? `
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        row-gap: 19px;`
      : `
        display: flex;
        justify-content: center;
  `}

  ${props =>
    props.fixedBToPhablet &&
    mediaQueries.toPhablet`
    width: 100vw;
    position: sticky;
    bottom: 0;
    box-shadow: 0px -20px 30px ${COLORS.gray};   
  `}

  ${props =>
    props.withRadiusBFromPhablet &&
    mediaQueries.fromTablet`
    border-radius: 0 0 4px 4px;
    box-shadow: 2px 10px 30px rgba(0, 0, 0, 0.04);
  `}

  ${mediaQueries.fromTablet`
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 16px;
    padding-top: 24px;
    padding-bottom: 24px;

    & > ${RedButton} {
      // switch red and black button order on tablet+ devices
      grid-row: 1;
      grid-column: 2;
    }
  `}
`;

export const ChoicesSummaryWrapper = styled.div<{
  withBorderB?: boolean;
  order?: number;
}>`
  ${horizontalPaddingMixin}

  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 20px;
  padding-top: 24px;
  order: ${props => props.order};
  border-top: 1px solid ${COLORS.gray};
  padding-top: 24px;

  ${props =>
    props.withBorderB &&
    `
    border-bottom: 1px solid ${COLORS.gray}; 
    padding-bottom: 24px;
  `}

  ${mediaQueries.fromTablet`
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 38px;
    column-gap: 19px;
  `}
`;

export const OtherContractsWrapper = styled.div`
  text-align: center;
  margin-top: 16px;

  ${mediaQueries.fromTablet`
    margin-top: 32px;
  `}
`;

export const HaveOtherContracts = styled.p`
  margin-bottom: 0;
`;

export const ChooseAnotherContract = styled(BaseButton).attrs({
  theme: buttonThemeFactory({
    backgroundColor: 'transparent',
    color: COLORS.amaranth,
    textDecoration: 'underline'
  })
})`
  padding: 0;
`;

export const LegalNotice = styled(BaseLegalNotice)`
  margin-top: 16px;

  ${mediaQueries.fromTablet`
    margin-top: 32px;
  `}
`;

export const ChoicesListTitle = styled.h2`
  ${horizontalPaddingMixin}

  width: 100%;
  text-align: center;
  font-weight: ${FONT_WEIGHTS.bold};
  font-family: ${FONT_FAMILIES.new};
  text-transform: uppercase;
  font-size: ${pxInRem(16)};
  padding: 24px;
  margin: 0;
  border-bottom: 1px solid ${COLORS.gray};
  background-color: ${COLORS.white};
  border-radius: 4px 4px 0 0;

  ${mediaQueries.fromTablet`
    text-align: left;
  `}
`;

export const Modal = styled(BaseModal).attrs({
  modalProps: {
    style: {
      overlay: {
        zIndex: 500
      }
    },
    isOpen: true
  }
})`
  overflow: visible !important;

  & ${ModalScrollWrapper} {
    overflow-y: visible;
  }
`;

export const ModalContentWrapper = styled.div`
  position: relative;
  max-width: 830px;
  background-color: ${COLORS.white};
  border-radius: 3px;
  margin: 0 20px;

  ${mediaQueries.fromTablet`
    margin: 0 auto;
  `}
`;

const ModalCloseIcon = styled(Icon).attrs({
  name: 'Close',
  width: '16px',
  height: '16px'
})`
  color: ${COLORS.white};
`;

export const ModalCloseButton = styled.button.attrs({
  children: <ModalCloseIcon />
})`
  position: absolute;
  right: 0;
  top: -32px;
  cursor: pointer;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 20px;
  border-bottom: 1px solid ${COLORS.gray};
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 20px;
`;

export const ModalTitle = styled.b`
  text-align: center;
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: ${pxInRem(16)};
`;

export const ModalCheckbox = styled(Checkbox)`
  width: 100%;
  border-color: ${props => (props.error ? COLORS.torchRed : COLORS.black)} &
    label {
    display: flex;
  }

  p {
    margin-bottom: 0;
  }

  & a {
    text-decoration: underline;
  }
`;

export const ModalInformationMessageWrapper = styled(InformationMessageWrapper)`
  width: 100%;
  margin: 16px 0 24px;
  align-items: center;
  line-height: 1.3;
`;

export const ModalInfoIconWrapper = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background-color: ${COLORS.amaranth};
  color: ${COLORS.white};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

export const ModalValidationButton = styled(RedButton)`
  margin: 0 auto;
  width: 287px;
`;

export const RecapExplanationWrapper = styled.div`
  ${horizontalPaddingMixin}

  border-top: 1px solid ${COLORS.gray};
  border-bottom: 1px solid ${COLORS.gray};
  padding: 24px 0;
`;

export const RecapExplanation = styled.p`
  line-height: 1.3;
  margin: 0 20px;
  ${mediaQueries.fromTablet`
    margin: 0 ;
  `}
`;

export const ErrorWrapper = styled(InformationMessageWrapper).attrs({
  backgroundColor: COLORS.concrete
})`
  margin-bottom: 50px;
  flex-direction: column;
`;

export const ErrorContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ErrorIconWrapper = styled.div`
  margin-right: 24px;
  width: 36px;
  color: ${COLORS.concrete};
  background-color: ${COLORS.amaranth};
  padding: 8px;
  border-radius: 50%;
`;

export const ErrorIcon = styled(Icon).attrs({
  name: 'Info',
  width: 16,
  height: 16
})``;

export const ErrorMessage = styled(InformationMessage)``;

export const ErrorCode = styled.p`
  font-size: ${pxInRem(16)};
  line-height: ${pxInRem(25)};
  text-align: right;
  margin-bottom: 0;
`;
