import { css } from 'styled-components';
import ReactModal from 'react-modal';

import { DIMENSIONS, FONT_FAMILIES, FONT_WEIGHTS } from '../../constants/theme';
import pxInRem from 'shared/helpers/styled-components/remHelper';
import { THEME_ONE_SHOP } from 'shared/constants/themeMaverick';

const {
  CONTAINER_INNER_WIDTH,
  CONTAINER_MINIMUM_PADDING,
  CONTAINER_MINIMUM_PADDING_TABLET
} = DIMENSIONS;

export default css`
  :root {
    // TEXT SIZE
    --text-size-h1: ${pxInRem(30)};
    --text-size-h2: ${pxInRem(25)};
    --text-size-h3: ${pxInRem(20)};
    --text-size-l: ${pxInRem(18)};
    --text-size-base: ${pxInRem(16)};
    --text-size-s: ${pxInRem(14)};
    --text-size-xs: ${pxInRem(12)};
    --text-size-xxl: ${pxInRem(50)};

    // LINE HEIGHT
    --line-height-xxl: ${pxInRem(40)};
    --line-height-xl: ${pxInRem(28)};
    --line-height-l: ${pxInRem(26)};
    --line-height-h3: ${pxInRem(24)};
    --line-height-m: ${pxInRem(20)};
    --line-height-base: ${pxInRem(18)};
    --line-height-s: ${pxInRem(16)};

    // DIMENSION
    --container-minimum-padding: ${CONTAINER_MINIMUM_PADDING}px;
    --container-minimum-padding-tablet: ${CONTAINER_MINIMUM_PADDING_TABLET}px;
    --container-inner-width: ${CONTAINER_INNER_WIDTH}px;
    --container-max-width-breakpoint: ${DIMENSIONS.CONTAINER_INNER_WIDTH +
    DIMENSIONS.CONTAINER_MINIMUM_PADDING_TABLET * 2}px;
    --half-of-container-inner-widh: CONTAINER_INNER_WIDTH / 2;

    --text-align: center;
    --spacing-xxl: 55px;

    // SPACE
    --spacing-xs: 8px;
    --spacing-s: 12px;
    --spacing-m: 16px;
    --spacing-l: 20px;
    --spacing-xl: 24px;
    --spacing-xxl: 48px;

    // RADIUS
    --border-radius-xxl: 10px;
    --border-radius-xxxl: 20px;

    //MODAL
    --modal-animation-duration: 300ms;
  }
  html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    font-size: 14px;

    body,
    input,
    button,
    textarea {
      font-family: ${FONT_FAMILIES.base};
    }

    body {
      font-size: 14px;
      margin: 0;

      h1,
      h2,
      h3 {
        font-family: ${FONT_FAMILIES.brand};
        font-weight: ${FONT_WEIGHTS.bold};
      }
    }

    &.search-open {
      overflow: hidden;
    }
  }

  * {
    &:before,
    &:after {
      box-sizing: inherit;
    }
    box-sizing: border-box;
  }

  img {
    max-width: 100%;
  }
  box-sizing: border-box;

  img {
    max-width: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    margin: 0 0 18px;
  }

  b,
  strong {
    font-weight: ${FONT_WEIGHTS.bold};
  }

  button,
  ul,
  ol {
    background: none;
    border: 0;
    margin: 0;
    outline: none;
    padding: 0;
  }

  ul,
  ol {
    list-style: none;
    margin: 0;
    outline: 0;
    padding: 0;
  }

  svg {
    fill: currentColor;
  }

  sup {
    font-size: 75%;
    line-height: inherit;
  }

  [role='button'] {
    cursor: pointer;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  [data-theme='one-shop'] {
    // SIZE
    --text-size-h3: ${pxInRem(22)};
    --text-size-l: ${pxInRem(18)};
    --text-size-m: ${pxInRem(16)};
    --text-size-s: ${pxInRem(14)};
    --text-size-xs: ${pxInRem(12)};

    // LINE HEIGHT
    --line-height-h3: ${pxInRem(22)};

    // SPACE
    --spacing-xs: 8px;
    --spacing-s: 12px;
    --spacing-m: 16px;
    --spacing-l: 20px;
    --spacing-xl: 24px;
    --spacing-xxl: 48px;

    // DIMENSIONS
    --container-minimum-padding: ${THEME_ONE_SHOP.DIMENSIONS
      .CONTAINER_MINIMUM_PADDING}px;
    --container-minimum-padding-tablet: ${THEME_ONE_SHOP.DIMENSIONS
      .CONTAINER_MINIMUM_PADDING_TABLET}px;
    --container-inner-width: ${CONTAINER_INNER_WIDTH}px;
    --container-max-width-breakpoint: ${THEME_ONE_SHOP.DIMENSIONS
      .CONTAINER_MAX_WIDTH_BREAKPOINT}px;
    --half-of-container-inner-widh: ${THEME_ONE_SHOP.DIMENSIONS
      .HALF_OF_CONTAINER_INNER_WIDTH};

    --text-align: ${THEME_ONE_SHOP.TEXT_ALIGN};
    --text-font-weight: ${FONT_WEIGHTS.demi};
    --title-margin: 0;
  }
`;

/**
 * Default styles are defined using class names in assets/css/modal.css
 */
ReactModal.defaultStyles = {};
