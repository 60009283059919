/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2017 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/*       */

import axios from 'axios';

import ConfigHelper from '../../../helpers/config/index';
import { INVALID_SLUG_FORMAT_ERROR } from 'shared/constants/api';

export const HttpClient = axios.create({
  baseURL: ConfigHelper.getClientConfig('api.structure.base_url'),
  timeout: ConfigHelper.getClientConfig('api.structure.timeout'),
  headers: {
    'X-UNIQUE-ID': '-'
  }
});

HttpClient.interceptors.request.use(requestInterceptor);

const INVALID_SLUG_FORMAT = /.*(http(s?)):\//i;

/**
 *
 * @param {AxiosRequestConfig} config
 * @returns
 */
export async function requestInterceptor(config) {
  if (INVALID_SLUG_FORMAT.test(config.url)) {
    throw new Error(`${INVALID_SLUG_FORMAT_ERROR} : ${config.url}`);
  }

  return config;
}
