import React from 'react';
import styled from 'styled-components';

import Arrow from 'shared/components/presentational/Slider/Arrow';

export const OneShopStyledArrowWrapper = styled.div<{
  isSecondaryDark?: boolean;
}>`
  width: 50px;
  height: 50px;
  background: ${props =>
    props.isSecondaryDark
      ? `var(--secondary-dark-default)`
      : `var(--secondary-light-default)`};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &:hover {
    background: ${props =>
      props.isSecondaryDark
        ? `var(--secondary-dark-hover)`
        : `var(--secondary-light-hover)`};
  }
`;

export const OneShopStyledArrow = styled(Arrow)<{ isSecondaryDark?: boolean }>`
  cursor: pointer;
  width: 50px;
  height: 50px;
  & > svg {
    width: 50px;
    height: 50px;
    color: ${props =>
      props.isSecondaryDark ? `var(--surface-light)` : `var(--surface-dark)`};

    &:hover {
      color: ${props =>
        props.isSecondaryDark ? `var(--surface-light)` : `var(--surface-dark)`};
    }
  }
`;

export const OneShopButtonWrapper = styled.div<{ isTransparent?: boolean }>`
  width: 130px;
  height: 100%;
  display: flex !important;
  align-items: center;
  ${props => props.isTransparent && `background-color: transparent;`}

  &:hover {
    ${props =>
      props.isTransparent && `background-color: transparent !important;`}
  }
  &::before {
    display: none;
    visibility: hidden;
  }
  &:first-child {
    transform: rotate(180deg);
  }
`;

export const OneShopArrow = ({
  name,
  isSecondaryDark,
  ...props
}: {
  name: string;
  isTransparent?: boolean;
  isSecondaryDark?: boolean;
}) => (
  <OneShopButtonWrapper {...props}>
    <OneShopStyledArrowWrapper isSecondaryDark={isSecondaryDark}>
      <OneShopStyledArrow name={name} isSecondaryDark={isSecondaryDark} />
    </OneShopStyledArrowWrapper>
  </OneShopButtonWrapper>
);
